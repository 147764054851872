export class RouteFormValidation {
  routeWidget: any;
  constructor(routeWidget: any) {
    this.routeWidget = routeWidget;
  }
  isValid(): boolean {
    if (!this.checkPointsFromAndTo()) {
      return false;
    }
    return true;
  }

  checkPointsFromAndTo(): boolean {
    if (
      !this.routeWidget.coordsFrom.lat &&
      !isNaN(this.routeWidget.coordsFrom.lat) &&
      !this.routeWidget.coordsFrom.lng &&
      !isNaN(this.routeWidget.coordsFrom.lng) &&
      !this.routeWidget.coordsTo.lat &&
      !isNaN(this.routeWidget.coordsTo.lat) &&
      !this.routeWidget.coordsTo.lng &&
      !isNaN(this.routeWidget.coordsTo.lng)
    ) {
      return true;
    } else {
      return false;
    }
  }
}

// = {

//     isValid(routeWidget: any): boolean {
//         const res = this.checkPointsFromAndTo(routeWidget);
//         return true;
//     },

//     checkPointsFromAndTo(routeWidget: any) {

//     },

//     checkPoints() {
//     },

// }
